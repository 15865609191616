(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('./src/nav')();
require('./src/smoothScroll')();
require('./src/filterBlock')();
require('./src/loadMore')();
require('./src/headerSliderBlock')();
require('./src/mapBlock')();
require('./src/modal')();

},{"./src/filterBlock":2,"./src/headerSliderBlock":3,"./src/loadMore":4,"./src/mapBlock":5,"./src/modal":6,"./src/nav":7,"./src/smoothScroll":8}],2:[function(require,module,exports){
'use strict';

/* global $ */

function init() {
  toggleFilterBlock();
  toggleFilterItemNames();
  clearFilters();
}

function toggleFilterBlock() {
  $('.filterBlock').on('click', '.filterBlock-title', function () {
    $('.filterBlock').toggleClass('filterBlock--open');
  });
}

function toggleFilterItemNames() {
  $('.filterBlock-filterItem').on('click', function () {
    $('.filterBlock-filterItem').removeClass('filterBlock-filterItem--filtered');
    $(this).addClass('filterBlock-filterItem--filtered');
    $(this).closest('.filterBlock').find('.filterBlock-clearContainer').addClass('filterBlock-clearContainer--open');
  });
}

function clearFilters() {
  $('.filterBlock-clearAll').on('click', function () {
    $(this).closest('.filterBlock').find('.filterBlock-filterItem').removeClass('filterBlock-filterItem--filtered');
    $(this).closest('.filterBlock-clearContainer').removeClass('filterBlock-clearContainer--open');
  });
}

module.exports = init;

},{}],3:[function(require,module,exports){
'use strict';

/* global $ */

function init() {
  $('.headerSliderBlock > .slider').slick({
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    dots: true,
    customPaging: pagination,
    asNavFor: '.headerSliderBlock .modal .slider'
  });

  $('.headerSliderBlock .modal .slider').slick({
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    asNavFor: '.headerSliderBlock > .slider'
  });
}

var prevArrow = '\n  <div class=\'sliderButton sliderButton--prev\' >\n    <div class=\'sliderButton-inner\'>\n      <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" style="vertical-align:middle;" data-reactid=".fsbnivw074.2.0.1.0.0.1:$1.0.0.0"><g data-reactid=".fsbnivw074.2.0.1.0.0.1:$1.0.0.0.0"><path d="m27.5 9l-11.7 11 11.7 11-1.6 1.5-13.4-12.5 13.4-12.5z" data-reactid=".fsbnivw074.2.0.1.0.0.1:$1.0.0.0.0.0"></path></g></svg>\n    </div>\n  </div>\n';

var nextArrow = '\n  <div class=\'sliderButton sliderButton--next\'>\n    <div class=\'sliderButton-inner\'>\n      <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" style="vertical-align:middle;" data-reactid=".fsbnivw074.2.0.1.0.0.1:$0.0.0.0"><g data-reactid=".fsbnivw074.2.0.1.0.0.1:$0.0.0.0.0"><path d="m12.5 9l1.6-1.5 13.4 12.5-13.4 12.5-1.6-1.5 11.7-11z" data-reactid=".fsbnivw074.2.0.1.0.0.1:$0.0.0.0.0.0"></path></g></svg>\n    </div>\n  </div>\n';
function pagination(slider, i) {
  return '<a> <span>' + (i + 1) + '</span> </a>';
}

module.exports = init;

},{}],4:[function(require,module,exports){
'use strict';

/* global $ */

function init() {
  $('.loadMore').click(function (event) {
    event.preventDefault();
    var count = $('.loadMore-target').children().length;
    var search = $(this).data('search');
    var tag = $(this).data('tag');
    var ppp = $(this).data('ppp');

    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: {
        action: 'load_more',
        offset: count,
        type: $(this).data('type'),
        tag: tag,
        search: search,
        ppp: ppp
      },
      success: function success(posts) {
        $('.js-sandbox').html(posts);
        var results = $('.js-sandbox').children().length;
        if (results > ppp) {
          $('.js-sandbox').children().last().remove();
        }
        $('.loadMore-target').append($('.js-sandbox').html());
        if (results <= ppp) {
          $('.loadMore').hide();
        }
      }
    });
  });
}

module.exports = init;

},{}],5:[function(require,module,exports){
'use strict';

/* global $ L alert */

function init() {
  var mapboxContainer = document.getElementById('mapBlock-map');
  if (mapboxContainer) {
    L.mapbox.accessToken = 'pk.eyJ1IjoibGFyYW5vZWxsIiwiYSI6ImNpa2g3OGwyZDAxNmJ2dGttc2o2OTdzaGYifQ.TlZiizXW-SbhamdaAd0E8g';

    var mapId = 'laranoell.p4behaa9';
    var geocoder = L.mapbox.geocoder('mapbox.places');
    var zoom = 11;
    var postalCode = 'M5V1J2';
    var mapboxMap = L.mapbox.map('mapBlock-map', mapId, { doubleClickZoom: false });
    var mapPin = $('.mapBlock').data('pin');
    var myIcon = L.icon({
      iconUrl: mapPin,
      iconSize: [20, 24],
      iconAnchor: [12, 12]
    });

    mapboxMap.dragging.disable();
    mapboxMap.touchZoom.disable();
    mapboxMap.doubleClickZoom.disable();
    mapboxMap.scrollWheelZoom.disable();
    mapboxMap.keyboard.disable();

    var setPosition = function setPosition(err, data) {
      if (err) {
        alert(err);
      } else if (data.latlng) {
        mapboxMap.setView(data.latlng, zoom);
        L.marker(data.latlng, { icon: myIcon }).addTo(mapboxMap);
      }
    };
    if ($('.mapBlock').data('postalCode')) {
      postalCode = $('.mapBlock').data('postalcode');
      if (geocoder) geocoder.query(postalCode, setPosition);
    }
  }
}

module.exports = init;

},{}],6:[function(require,module,exports){
'use strict';

/* global $ */

function init() {
  $('.modal-trigger').click(function () {
    $(this).closest('.modal').addClass('modal--visible').removeClass('modal--clicked');
  });

  $('.modal-close').click(function () {
    $(this).closest('.modal').removeClass('modal--visible').addClass('modal--clicked');
  });
}

module.exports = init;

},{}],7:[function(require,module,exports){
'use strict';

/* global $ */

function toggleMobilenav() {
  $('.primaryNav-menuIcon').on('click', function () {
    $('.primaryNav').toggleClass('primaryNav--open');
  });
}

module.exports = toggleMobilenav;

},{}],8:[function(require,module,exports){
'use strict';

/* global $ */

module.exports = function () {
  $('body').on('click', '.smoothScroll', function (e) {
    var headerHeight = $('.siteHeader:visible').height();
    e.preventDefault();
    $('html,body').animate({
      scrollTop: $(this.hash).offset().top - headerHeight
    }, 600);
  });
};

},{}]},{},[1]);
